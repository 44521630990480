import React from "react";
import { Modal as MelodiesModal } from "melodies-source/Modal";
import styled from "styled-components";
import { Button, ButtonType } from "melodies-source/Button";

interface Action {
  text: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  variant?: ButtonType;
}
interface Props extends React.ComponentProps<typeof MelodiesModal> {
  actions?: Record<"secondary" | "main", Action>;
  isForm?: boolean;
}

export const Modal: React.FC<Props> = ({
  children,
  actions,
  isForm,
  ...props
}) => {
  const content = (
    <>
      {children}
      {actions && (
        <Actions>
          <Button
            variant={actions.secondary.variant || "tertiary"}
            onClick={actions.secondary.onClick}
            disabled={actions.secondary.disabled}
          >
            {actions.secondary.text}
          </Button>
          <Button
            variant={actions.main.variant || "primary"}
            loading={actions.main.loading}
            disabled={actions.main.disabled}
            {...(actions.main.onClick
              ? { onClick: actions.main.onClick }
              : { type: "submit" })}
          >
            {actions.main.text}
          </Button>
        </Actions>
      )}
    </>
  );
  return (
    <MelodiesModal {...props}>
      {isForm ? (
        <Form onSubmit={actions.main.onClick}>{content}</Form>
      ) : (
        content
      )}
    </MelodiesModal>
  );
};

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 30px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 100%;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
